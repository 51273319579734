import React, { useState, useEffect, useRef } from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { FaLinkedinIn } from 'react-icons/fa';

import StepName from '../components/joinus/stepName';
import StepEmail from '../components/joinus/stepEmail';
import StepRecap from '../components/joinus/stepRecap';
import StepJob from '../components/joinus/stepJob';
import StepPortfolio from '../components/joinus/stepPortfolio';
import StepMessage from '../components/joinus/stepMessage';
import Stepper from '../components/joinus/stepper';
import Honeyspot from '../components/joinus/honeyspot';

const JoinUs = ({ intl }) => {
  const [bkgColor, setBkgColor] = useState('bkg-color2');
  const [isIntro, setIsIntro] = useState(true);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [actualStep, setActualStep] = useState();
  const [status, setStatus] = useState();

  const [steps, setSteps] = useState();

  // FORM FIELDS
  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userJob, setUserJob] = useState('');
  const [userMessage, setUserMessage] = useState('');
  const [userPortfolio, setUserPortfolio] = useState('');
  const [honeyspotName, setHoneyspotName] = useState(false);
  const [honeyspotEmail, setHoneyspotEmail] = useState(false);

  // HANDLE FIELDS FORM
  const handleChangeUserName = value => {
    setUserName(value);
  };

  const handleChangeUserEmail = value => {
    setUserEmail(value);
  };

  const handleChangeUserJob = value => {
    setUserJob(value);
  };

  const handleChangeUserMessage = value => {
    setUserMessage(value);
  };

  const handleChangeUserPortfolio = value => {
    setUserPortfolio(value);
  };

  const handleChangeHoneyspotName = value => {
    setHoneyspotName(value);
  };

  const handleChangeHoneyspotEmail = value => {
    setHoneyspotEmail(value);
  };

  const stepName = useRef();
  const stepEmail = useRef();
  const stepJobs = useRef();
  const stepMessage = useRef();
  const stepPortfolio = useRef();
  const stepRecap = useRef();

  useEffect(() => {
    setActualStep(stepName);
  }, [stepName]);

  useEffect(() => {
    setSteps([
      stepName,
      stepEmail,
      stepJobs,
      stepMessage,
      stepPortfolio,
      stepRecap,
    ]);
  }, [stepName, stepEmail, stepJobs, stepMessage, stepPortfolio, stepRecap]);

  // Animate Link
  const animLinkIn = event => {
    event.target.classList.add('in');
  };

  const animLinkOut = event => {
    event.target.classList.remove('in');
    event.target.classList.add('out');

    setTimeout(() => {
      event.target.classList.remove('out');
    }, 1000);
  };

  const handlePrevStep = (event, stepTargeted) => {
    event.preventDefault();
    setActualStep(stepTargeted);
    stepTargeted.current.scrollIntoView();
    setBkgColor('bkg-color2');
  };

  const handleNextStep = (event, stepTargeted, isLast) => {
    event.preventDefault();
    setActualStep(stepTargeted);
    stepTargeted.current.scrollIntoView();
    if (isLast) {
      setBkgColor('bkg-color3');
    } else {
      setBkgColor('bkg-color2');
    }
  };

  const onSubmit = async e => {
    e.preventDefault();

    if (honeyspotName || honeyspotEmail) {
      window.location = '/';
    } else {
      const form = document.getElementById('contact-form');
      const data = new FormData(form);

      {
        /* https://formsubmit.co/4d469273f64d625d1fe640dc4976ac81 */
      }
      const response = await fetch(
        'https://formsubmit.co/penelope.vereecken@epsilon-france.com',
        {
          method: 'POST',
          body: data,
          headers: {
            Accept: 'application/json',
          },
        }
      );

      if (response?.ok) {
        setBkgColor('bkg-color2');
        setIsConfirmation(true);
        form.reset();
      } else {
        setStatus('Oops! There was a problem submitting your form');
      }
    }
  };

  return (
    <Layout
      headerCssClass={bkgColor}
      mainCssClass={`contact-page ${bkgColor}`}
      activeMenuLink="joinus"
    >
      <SEO
        title={intl.formatMessage({ id: 'seo.title_joinus' })}
        titleOnglet={intl.formatMessage({ id: 'seo.title_joinus' })}
      />
      <div className="content">
        {isIntro && (
          <div className="form intro">
            <h1
              className="title mb-6 is-size-3 is-uppercase"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'joinus.intro.title',
                }),
              }}
            />
            <p
              className="mb-5 txt-intro is-size-5 pb-2"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'joinus.intro.text',
                }),
              }}
            />
            <p className="is-uppercase mb-5 is-size-6 has-icon">
              <FormattedMessage id="joinus.intro.text_linkedin" />
              <a
                href="https://www.linkedin.com/company/publicis-conseil/jobs/ "
                target="_blank"
                rel="noreferrer"
                title={intl.formatMessage({
                  id: 'joinus.intro.title_linkedin',
                })}
              >
                <FaLinkedinIn className="icon" />
              </a>
            </p>
            <p className="is-uppercase is-size-6">
              <FormattedMessage id="joinus.intro.candidate" />
            </p>
            <button
              className="button is-uppercase"
              onClick={() => setIsIntro(false)}
            >
              <FormattedMessage id="joinus.intro.startform" />
            </button>
          </div>
        )}

        {/*** FORM STEP BY STEP ***/}
        {!isIntro && !isConfirmation && (
          <div className="form-stepper">
            <Stepper steps={steps} currentStep={actualStep} />

            <form onSubmit={e => onSubmit(e)} id="contact-form">
              {/* HoneySpot */}
              <Honeyspot
                onChangeName={handleChangeHoneyspotName}
                onChangeEmail={handleChangeHoneyspotEmail}
              />

              {/* NAME */}
              <StepName
                refCurrent={stepName}
                actualStep={actualStep}
                handleInputChange={handleChangeUserName}
                handleNextStep={handleNextStep}
                nextRef={stepEmail}
              />

              {/* EMAIL */}
              <StepEmail
                refCurrent={stepEmail}
                actualStep={actualStep}
                nextRef={stepJobs}
                prevRef={stepName}
                handleInputChange={handleChangeUserEmail}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                animLinkIn={animLinkIn}
                animLinkOut={animLinkOut}
              />

              {/* JOB */}
              <StepJob
                refCurrent={stepJobs}
                actualStep={actualStep}
                nextRef={stepMessage}
                prevRef={stepEmail}
                handleInputChange={handleChangeUserJob}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                animLinkIn={animLinkIn}
                animLinkOut={animLinkOut}
              />

              {/* MESSAGE */}
              <StepMessage
                refCurrent={stepMessage}
                actualStep={actualStep}
                nextRef={stepPortfolio}
                prevRef={stepJobs}
                handleInputChange={handleChangeUserMessage}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                animLinkIn={animLinkIn}
                animLinkOut={animLinkOut}
              />

              {/* PORTFOLIO */}
              <StepPortfolio
                refCurrent={stepPortfolio}
                actualStep={actualStep}
                nextRef={stepRecap}
                prevRef={stepMessage}
                handleInputChange={handleChangeUserPortfolio}
                handleNextStep={handleNextStep}
                handlePrevStep={handlePrevStep}
                animLinkIn={animLinkIn}
                animLinkOut={animLinkOut}
              />

              {/* RECAP */}
              <StepRecap
                refCurrent={stepRecap}
                actualStep={actualStep}
                prevRef={stepPortfolio}
                handlePrevStep={handlePrevStep}
                animLinkIn={animLinkIn}
                animLinkOut={animLinkOut}
                name={userName}
                email={userEmail}
                job={userJob}
                message={userMessage}
                portfolio={userPortfolio}
                status={status}
              />
            </form>
          </div>
        )}

        {/*** CONFIRMATION ***/}
        {!isIntro && isConfirmation && (
          <div className="form confirmation">
            <h1
              className="mb-5 is-uppercase"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'joinus.form.confirmation.thanks',
                }),
              }}
            />
            <div
              className="mb-5 is-uppercase"
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({
                  id: 'joinus.form.confirmation.success',
                }),
              }}
            />
          </div>
        )}
      </div>
    </Layout>
  );
};

export default injectIntl(JoinUs);
