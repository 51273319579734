import React from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

import AnimateLink from '../animateLink';

const StepRecap = props => {
  const {
    intl,
    name,
    email,
    job,
    message,
    portfolio,
    status,
    refCurrent,
    prevRef,
    actualStep,
    handlePrevStep,
    animLinkIn,
    animLinkOut,
  } = props;
  return (
    <div
      className={`form contact-form__content ${
        actualStep !== refCurrent ? 'is-invisible' : ''
      }`}
      aria-hidden={actualStep !== refCurrent}
      ref={refCurrent}
    >
      <h2 className="label title is-2 mb-6">
        <FormattedMessage id="joinus.form.send.label" />
      </h2>
      <div className="columns">
        <div className="column">
          <p>
            <span>
              <FormattedMessage id="joinus.form.send.name" />
            </span>
            {name}
          </p>
          <p>
            <span>
              <FormattedMessage id="joinus.form.send.email" />
            </span>
            {email}
          </p>
        </div>
        <div className="column">
          <p>
            <span>
              <FormattedMessage id="joinus.form.send.profile" />
            </span>
            {job}
          </p>
          <p>
            <span>
              <FormattedMessage id="joinus.form.send.link" />
            </span>
            <AnimateLink
              externalLink={true}
              url={portfolio}
              cssClassLink="link-menu-ext has-text-white"
              tabIndex={actualStep !== refCurrent ? '-1' : '0'}
            >
              {portfolio} <span className="icon-icon-menu"></span>
            </AnimateLink>
          </p>
        </div>
      </div>
      <div className="field">
        <p>
          <span>
            <FormattedMessage id="joinus.form.send.about" />
          </span>
          {message}
        </p>
      </div>
      <div className="field btn-wrapper two-buttons">
        <div className="control">
          <a
            className="link prev is-uppercase"
            onClick={e => handlePrevStep(e, prevRef)}
            onMouseEnter={e => animLinkIn(e)}
            onMouseLeave={e => animLinkOut(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
            onKeyUp={e => {
              if (e.keyCode == 13) {
                handlePrevStep(e, prevRef);
              }
            }}
          >
            <span className="icon-arrow"></span>
            <FormattedMessage id="joinus.form.send.no" />
          </a>
          <button className="button is-uppercase" type="submit">
            <FormattedMessage id="joinus.form.send.yes" />
          </button>
        </div>
      </div>
      <input
        type="hidden"
        name="_subject"
        value={intl.formatMessage({
          id: 'joinus.form.subject.value',
        })}
        aria-hidden={true}
        tabIndex={actualStep !== refCurrent ? '-1' : '0'}
      />
      <input
        type="hidden"
        name="_captcha"
        value="false"
        aria-hidden={true}
        tabIndex={actualStep !== refCurrent ? '-1' : '0'}
      />
      <input
        type="hidden"
        name="_template"
        value="box"
        aria-hidden={true}
        tabIndex={actualStep !== refCurrent ? '-1' : '0'}
      />
      {/* ambre.longeard@conseil.publicis.fr */}
      <input
        type="hidden"
        name="_cc"
        value="amandine.vandevelde@epsilon-france.com"
        aria-hidden={true}
        tabIndex={actualStep !== refCurrent ? '-1' : '0'}
      />

      {status && <p className="help is-danger">{status}</p>}

      <p
        className="has-text-white is-size-7 section p-0 mt-6"
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({ id: 'joinus.legales' }),
        }}
      />
    </div>
  );
};

export default injectIntl(StepRecap);
