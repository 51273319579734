import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

const StepMessage = props => {
  const {
    intl,
    refCurrent,
    prevRef,
    nextRef,
    actualStep,
    handleInputChange,
    handleNextStep,
    handlePrevStep,
    animLinkIn,
    animLinkOut,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);
  const [maxLength, setMaxLength] = useState(300);

  const handleChange = e => {
    setHasError(false);
    e.target.setCustomValidity('');
    setInputValue(e.target.value);
  };

  const onSubmit = e => {
    // Check field
    if (inputValue === '' || inputValue.length > maxLength) {
      setHasError(true);
    } else {
      handleInputChange(inputValue);
      handleNextStep(e, nextRef);
    }
  };

  return (
    <fieldset
      className={`form ${actualStep !== refCurrent ? 'is-invisible' : ''}`}
      aria-hidden={actualStep !== refCurrent}
      ref={refCurrent}
    >
      <div className="field">
        <label
          className="label title is-2 mb-6"
          htmlFor="messagePubConseil2023"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'joinus.form.message.label',
            }),
          }}
        />
        <div className="control">
          <textarea
            className="textarea has-fixed-size"
            placeholder={intl.formatMessage({
              id: 'joinus.form.message.placeholder',
            })}
            maxLength="300"
            rows="10"
            name="messagePubConseil2023"
            id="messagePubConseil2023"
            onChange={handleChange}
            required
            onInvalid={e => {
              e.target.setCustomValidity(
                intl.formatMessage({
                  id: 'joinus.form.message.error',
                })
              );
            }}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          ></textarea>
          <span className="message--length">
            {inputValue.length}/{maxLength}
          </span>
        </div>
        {hasError && (
          <p className="help is-danger">
            <FormattedMessage id="joinus.form.message.error" />
          </p>
        )}
      </div>
      <div className="field btn-wrapper two-buttons">
        <div className="control">
          <a
            className="link prev is-uppercase"
            onClick={e => handlePrevStep(e, prevRef)}
            onMouseEnter={e => animLinkIn(e)}
            onMouseLeave={e => animLinkOut(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
            onKeyUp={e => {
              if (e.keyCode == 13) {
                handlePrevStep(e, prevRef);
              }
            }}
          >
            <span className="icon-arrow"></span>
            <FormattedMessage id="joinus.form.previous" />
          </a>
          <button
            className="button is-uppercase"
            type="button"
            onClick={e => onSubmit(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          >
            <FormattedMessage id="joinus.form.next" />
          </button>
        </div>
      </div>
    </fieldset>
  );
};

export default injectIntl(StepMessage);
