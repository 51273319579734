import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

const StepJob = props => {
  const {
    intl,
    refCurrent,
    prevRef,
    nextRef,
    actualStep,
    handleInputChange,
    handleNextStep,
    handlePrevStep,
    animLinkIn,
    animLinkOut,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleChange = e => {
    setHasError(false);
    console.log(e.target.value);
    setInputValue(e.target.value);
  };

  const onSubmit = e => {
    // Check field
    if (inputValue === '') {
      setHasError(true);
    } else {
      handleInputChange(inputValue);
      handleNextStep(e, nextRef);
    }
  };

  return (
    <fieldset
      className={`form ${actualStep !== refCurrent ? 'is-invisible' : ''}`}
      aria-hidden={actualStep !== refCurrent}
      ref={refCurrent}
    >
      <div className="field">
        <label
          className="label title is-2 mb-6"
          htmlFor="jobPubConseil2023"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'joinus.form.job.label',
            }),
          }}
        />
        <div
          className="control listing-jobs"
          onChange={handleChange}
          onKeyUp={e => {
            if (e.keyCode == 13) {
              onSubmit(e);
            }
          }}
        >
          <input
            type="radio"
            id="jobCreation"
            name="jobPubConseil2023"
            value={intl.formatMessage({
              id: 'joinus.form.job.creation',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobCreation"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.creation',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.creation" />
          </label>

          <input
            type="radio"
            id="jobCommercial"
            name="jobPubConseil2023"
            value={intl.formatMessage({
              id: 'joinus.form.job.commercial',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobCommercial"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.commercial',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.commercial" />
          </label>

          <input
            type="radio"
            id="jobPlanning"
            name="jobPubConseil2023"
            value={intl.formatMessage({
              id: 'joinus.form.job.planning',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobPlanning"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.planning',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.planning" />
          </label>

          <input
            type="radio"
            id="jobSocial"
            name="jobPubConseil2023"
            value={intl.formatMessage({
              id: 'joinus.form.job.social',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobSocial"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.social',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.social" />
          </label>

          <input
            type="radio"
            id="jobInternship"
            name="jobPubConseil2023"
            value={intl.formatMessage({
              id: 'joinus.form.job.internship',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobInternship"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.internship',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.internship" />
          </label>

          <input
            type="radio"
            id="jobRh"
            name="jobPubConseil2023"
            value={intl.formatMessage({
              id: 'joinus.form.job.rh',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobRh"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.rh',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.rh" />
          </label>

          <input
            type="radio"
            name="jobPubConseil2023"
            id="jobCsr"
            value={intl.formatMessage({
              id: 'joinus.form.job.csr',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobCsr"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.csr',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.csr" />
          </label>

          <input
            type="radio"
            name="jobPubConseil2023"
            id="jobOther"
            value={intl.formatMessage({
              id: 'joinus.form.job.other',
            })}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
          <label
            htmlFor="jobOther"
            className={
              inputValue ==
              intl.formatMessage({
                id: 'joinus.form.job.other',
              })
                ? 'radio is-selected'
                : 'radio'
            }
          >
            <FormattedMessage id="joinus.form.job.other" />
          </label>
        </div>
        {hasError && (
          <p className="help is-danger">
            <FormattedMessage id="joinus.form.help-danger" />
          </p>
        )}
      </div>
      <div className="field btn-wrapper two-buttons">
        <div className="control">
          <a
            className="link prev is-uppercase"
            onClick={e => handlePrevStep(e, prevRef)}
            onMouseEnter={e => animLinkIn(e)}
            onMouseLeave={e => animLinkOut(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
            onKeyUp={e => {
              if (e.keyCode == 13) {
                handlePrevStep(e, prevRef);
              }
            }}
          >
            <span className="icon-arrow"></span>
            <FormattedMessage id="joinus.form.previous" />
          </a>
          <button
            className="button is-uppercase"
            type="button"
            onClick={e => onSubmit(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          >
            <FormattedMessage id="joinus.form.next" />
          </button>
        </div>
      </div>
    </fieldset>
  );
};

export default injectIntl(StepJob);
