import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

const StepName = props => {
  const {
    intl,
    refCurrent,
    nextRef,
    actualStep,
    handleInputChange,
    handleNextStep,
  } = props;

  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleChange = e => {
    setHasError(false);
    e.target.setCustomValidity('');
    setInputValue(e.target.value);
  };

  const onSubmit = e => {
    const nameRegex =
      /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

    // Check field
    if (inputValue === '' || !nameRegex.test(inputValue)) {
      setHasError(true);
    } else {
      handleInputChange(inputValue);
      handleNextStep(e, nextRef);
    }
  };

  return (
    <fieldset
      className={`form form-name ${
        actualStep !== refCurrent ? 'is-invisible' : ''
      }`}
      aria-hidden={actualStep !== refCurrent}
      ref={refCurrent}
    >
      <div className="field">
        <label
          className="label title is-2 mb-6"
          htmlFor="namePubConseil2023"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'joinus.form.name.label',
            }),
          }}
        />
        <div className="control">
          <input
            className="input"
            type="text"
            name="namePubConseil2023"
            id="namePubConseil2023"
            maxLength="100"
            required
            placeholder={intl.formatMessage({
              id: 'joinus.form.name.placeholder',
            })}
            onChange={handleChange}
            autoComplete="off"
            onInvalid={e => {
              e.target.setCustomValidity(
                intl.formatMessage({
                  id: 'joinus.form.name.error',
                })
              );
            }}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
        </div>
        {hasError && (
          <p className="help is-danger">
            <FormattedMessage id="joinus.form.name.error" />
          </p>
        )}
      </div>
      <div className="field btn-wrapper">
        <div className="control">
          <button
            className="button is-uppercase"
            type="button"
            onClick={e => onSubmit(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          >
            <FormattedMessage id="joinus.form.next" />
          </button>
        </div>
      </div>
    </fieldset>
  );
};

export default injectIntl(StepName);
