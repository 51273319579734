import React from 'react';

const Honeyspot = ({ onChangeName, onChangeEmail }) => {
  return (
    <div>
      <label className="ohnohoney" htmlFor="name"></label>
      <input
        className="ohnohoney"
        autoComplete="off"
        type="text"
        id="name"
        name="name"
        placeholder="Your name here"
        onChange={e => onChangeName(e.target.value)}
        aria-hidden="true"
        tabIndex="-1"
      />
      <label className="ohnohoney" htmlFor="email"></label>
      <input
        className="ohnohoney"
        autoComplete="off"
        type="email"
        id="email"
        name="email"
        placeholder="Your e-mail here"
        onChange={e => onChangeEmail(e.target.value)}
        aria-hidden="true"
        tabIndex="-1"
      />
    </div>
  );
};

export default Honeyspot;
