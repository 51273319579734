import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'gatsby-plugin-intl';

const StepPortfolio = props => {
  const {
    intl,
    refCurrent,
    prevRef,
    nextRef,
    actualStep,
    handleInputChange,
    handleNextStep,
    handlePrevStep,
    animLinkIn,
    animLinkOut,
  } = props;
  const [inputValue, setInputValue] = useState('');
  const [hasError, setHasError] = useState(false);

  const handleChange = e => {
    setHasError(false);
    e.target.setCustomValidity('');
    setInputValue(e.target.value);
  };

  const onSubmit = e => {
    const nameRegex =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    // Check field
    if (inputValue === '' || !nameRegex.test(inputValue)) {
      setHasError(true);
    } else {
      handleInputChange(inputValue);
      handleNextStep(e, nextRef, true);
    }
  };

  return (
    <fieldset
      className={`form ${actualStep !== refCurrent ? 'is-invisible' : ''}`}
      aria-hidden={actualStep !== refCurrent}
      ref={refCurrent}
    >
      <div className="field">
        <label
          className="label title is-2 mb-6"
          htmlFor="portfolioPubConseil2023"
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({
              id: 'joinus.form.portfolio.label',
            }),
          }}
        />
        <div className="control">
          <input
            className="input"
            type="text"
            name="portfolioPubConseil2023"
            id="portfolioPubConseil2023"
            maxLength="100"
            required
            placeholder={intl.formatMessage({
              id: 'joinus.form.portfolio.placeholder',
            })}
            onChange={handleChange}
            autoComplete="off"
            onInvalid={e => {
              e.target.setCustomValidity(
                intl.formatMessage({
                  id: 'joinus.form.portfolio.error',
                })
              );
            }}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          />
        </div>
        {hasError && (
          <p className="help is-danger">
            <FormattedMessage id="joinus.form.portfolio.error" />
          </p>
        )}
      </div>
      <div className="field btn-wrapper two-buttons">
        <div className="control">
          <a
            className="link prev is-uppercase"
            onClick={e => handlePrevStep(e, prevRef)}
            onMouseEnter={e => animLinkIn(e)}
            onMouseLeave={e => animLinkOut(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
            onKeyUp={e => {
              if (e.keyCode == 13) {
                handlePrevStep(e, prevRef);
              }
            }}
          >
            <span className="icon-arrow"></span>
            <FormattedMessage id="joinus.form.previous" />
          </a>
          <button
            className="button is-uppercase"
            type="button"
            onClick={e => onSubmit(e)}
            tabIndex={actualStep !== refCurrent ? '-1' : '0'}
          >
            <FormattedMessage id="joinus.form.next" />
          </button>
        </div>
      </div>
    </fieldset>
  );
};

export default injectIntl(StepPortfolio);
