import React, { useState, useEffect } from 'react';

const Stepper = ({ steps, currentStep }) => {
  return (
    <ul className="dots">
      {steps.map((step, index) => {
        return (
          <li
            key={`step--${index}`}
            className={step === currentStep ? 'is-active' : ''}
          >
            <span />
          </li>
        );
      })}
    </ul>
  );
};

export default Stepper;
